<template>
  <div>
    <v-overlay :value="overlay" absolute style="z-index:999999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-toolbar flat>
      <v-icon left>mdi-account-box-outline</v-icon>
      <v-toolbar-title>LEADS</v-toolbar-title>
      <v-divider class="mx-4" vertical></v-divider>
      <v-btn @click="abrirCrearLeadDialog(null)" color="blue" small dark
        ><v-icon>mdi-plus</v-icon> Crear</v-btn
      >
    </v-toolbar>
    <v-toolbar flat>
      <v-col>
        <v-text-field
          clearable
          label="Código"
          v-model="busCodigo"
          append-icon="mdi-magnify"
          @click:append="cargarLista"
          @keyup.enter="cargarLista"
          
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          clearable
          label="Nombres"
          append-icon="mdi-magnify"
          @click:append="cargarLista"
          v-model="busNombre"
          @keyup.enter="cargarLista"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          clearable
          label="Teléfono"
          v-model="busTelefono"
          append-icon="mdi-magnify"
          @click:append="cargarLista"
          @keyup.enter="cargarLista"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          clearable
          v-model="busEstado"
          label="Estado"
          item-text="description"
          item-value="status_id"
          :items="estados"
          @change="cargarLista"
        >
        </v-select>
        
      </v-col>
      <v-col>
        <v-text-field
          clearable
          label="Asignado a"
          v-model="busAsignado"
          append-icon="mdi-magnify"
          @click:append="cargarLista"
          @keyup.enter="cargarLista"
        ></v-text-field>
      </v-col>
      <v-col>
        
        <v-menu>
        <template v-slot:activator="{ on, attrs }">
        <v-text-field class="pt-1" v-model="dateRangeText" label="Intervalo de fechas"
            persistent-hint readonly append-icon="mdi-calendar-multiple" v-bind="attrs"
            v-on="on" clearable @click:clear="limpiarFecha" />
        </template>
        <v-date-picker range v-model="busFecha" no-title @change="cargarLista" />
        </v-menu>
        <!-- <v-text-field
          clearable
          label="Fecha creación"
          v-model="busFecha"
          @keyup.enter="cargarLista"
        ></v-text-field> -->
      </v-col>
    </v-toolbar>
    <v-data-table
      :headers="headersLead"
      :items-per-page="itemsPerPage"
      :items="leads"
      class="elevation-1"
      small
      hide-default-footer
    >
      <template v-slot:item.party_id="{ item }">
        <!-- <v-btn text small @click="abrirLeadDialog(item.party_id)">
          {{ item.party_id }}
        </v-btn> -->

        <v-btn
          small
          text
          title="Abrir lead"
          :to="`/lead_form/${item.party_id}`"
        >
          <!-- <v-icon color="info">mdi-eye</v-icon> -->
          {{ item.party_id }}
        </v-btn>
      </template>

      <template v-slot:footer>
        <v-pagination
          class="mt-10"
          v-model="currentPage"
          :length="pageCount"
          @input="handlePageChange"
          total-visible="10"
        ></v-pagination>
      </template>
    </v-data-table>

    <!-- dialogo editar lead -->
    <v-dialog v-model="dialogoLead" fullscreen>
      <v-card>
        <v-form ref="formLead">
          <v-toolbar dark color="blue" height="40" flat>
            <v-icon left>mdi-account-box-outline</v-icon>
            Lead # {{ datosLead.cliente_nombres }} :: {{ datosLead.party_id }}
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogoLead = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <LeadForm></LeadForm>
          <!-- <v-toolbar height="40" flat class="pt-4">
            <v-btn @click="guardarLead" color="blue" small dark>
              <v-icon>mdi-content-save</v-icon>Guardar</v-btn
            >
            <v-divider class="mx-6" vertical flat></v-divider>
            <v-btn @click="dialogCotizar = true" color="green" small dark>
              <v-icon>mdi-currency-usd</v-icon>Cotizar
            </v-btn>
          </v-toolbar>

          <v-row class="mt-1 mx-2">
            <v-col cols="3">
              <v-text-field
                v-model="datosLead.first_name"
                label="Nombres"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="datosLead.last_name"
                label="Apellidos"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="estados"
                v-model="datosLead.status_id"
                item-text="description"
                item-value="status_id"
                label="Estado"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-1 mx-2">
            <v-col>
              <v-text-field
                :rules="validarTelefono"
                v-model="datosLead.telefono"
                label="Teléfono"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                :rules="validarEmail"
                v-model="datosLead.email"
                label="Email"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-1 mx-2">
            <v-col md="6" class="py-0 my-0">
              <v-select
                :items="fuentes"
                label="Fuente"
                item-text="description"
                item-value="data_source_id"
                v-model="datosLead.data_source_id"
              >
              </v-select>
            </v-col>

            <v-col md="6" class="py-0 my-0">
              <v-select
                :items="servicios"
                label="Servicio"
                item-text="nombre"
                item-value="servicio_crm_id"
                v-model="datosLead.servicio_lead_id"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row class="mt-1 mx-2">
            <v-col md="6" class="py-0 my-0">
              <v-select
                :items="campanias"
                label="Campaña"
                item-text="campaign_name"
                item-value="marketing_campaign_id"
                v-model="datosLead.marketing_campaign_id"
              >
              </v-select>
            </v-col>
            <v-col md="6" class="py-0 my-0">
              <v-select
                :items="getAnuncios"
                label="Anuncio"
                item-text="descripcion"
                item-value="mk_anuncio_id"
                v-model="datosLead.mk_anuncio_id"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row class="mt-1 mx-2">
            <v-col md="6" class="py-0 my-0">
              <v-textarea
                v-model="datosLead.description"
                label="Observaciones"
              ></v-textarea>
            </v-col>
            <v-col md="6" class="py-0 my-0">
              <v-text-field
                v-model="datosLead.created_by_user_login"
                label="Asignado A"
              ></v-text-field>
            </v-col>
          </v-row> -->
        </v-form>
      </v-card>
    </v-dialog>

    <!-- DIALOG OPCIONES NUEVO LEAD -->
    <v-dialog v-model="dialogCotizar" width="500">
      <v-form ref="formCrearOportunidad">
        <v-card class="pa-0 ma-0">
          <v-toolbar dark color="blue" height="40" flat>
            Crear cotización
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogCotizar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="pa-4">
            <v-col cols="12">
              <v-select
                :rules="requiredRule"
                v-model="product_store_id"
                label="Establecimiento"
                :items="tiendas"
                item-text="store_name"
                item-value="product_store_id"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                :rules="requiredRule"
                v-model="pipeline_id"
                label="Pipeline"
                :items="pipelines"
                item-text="pipeline_name"
                item-value="pipeline_id"
              ></v-select>
            </v-col>
          </v-row>
          <!-- <v-row>
                    <v-data-table :headers="headersOportLead" :items-per-page="itemsPerPage" :items="oportunidadesLead" class="elevation-1" small
                                hide-default-footer>
                        <template v-slot:item.party_id="{ item }"> >
                        </template> 
                    </v-data-table>
                </v-row> -->
          <v-card-actions class="pb-4 pl-4">
            <v-btn small dark color="green" @click="crearOportunidad()"
              ><v-icon left>mdi-content-save</v-icon> Crear oportunidad</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- DIALOG NUEVO LEAD -->
    <v-dialog v-model="dialogCrearLead" persistent width="700">
      <v-form ref="formCrearLead">
        <v-card>
          <v-toolbar dark color="blue" height="40" flat>
            <v-icon left>mdi-account-box-outline</v-icon>
            Crear nuevo lead
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogCrearLead = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="6" class="py-0 my-0">
              <v-text-field
                :rules="requiredRule"
                v-model="datosLead.first_name"
                label="Nombres"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="py-0 my-0">
              <v-text-field
                v-model="datosLead.last_name"
                label="Apellidos"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="py-0 my-0">
              <v-text-field
                :rules="validarTelefono"
                v-model="datosLead.telefono"
                label="Teléfono"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="py-0 my-0">
              <v-text-field
                :rules="validarEmail"
                v-model="datosLead.email"
                label="Email"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="py-0 my-0">
              <v-select
                :rules="requiredRule"
                :items="fuentes"
                label="Fuente"
                item-text="description"
                item-value="data_source_id"
                v-model="datosLead.data_source_id"
              >
              </v-select>
            </v-col>

            <v-col md="6" class="py-0 my-0">
              <v-select
                :items="campanias"
                label="Campaña"
                item-text="campaign_name"
                item-value="marketing_campaign_id"
                v-model="datosLead.marketing_campaign_id"
              >
              </v-select>
            </v-col>

            <v-col md="6" class="py-0 my-0">
              <v-select
                :items="getAnuncios"
                label="Anuncio"
                item-text="descripcion"
                item-value="mk_anuncio_id"
                v-model="datosLead.mk_anuncio_id"
              >
              </v-select>
            </v-col>

            <v-col md="6" class="pt-0 my-0">
              <v-select
                :rules="requiredRule"
                :items="servicios"
                label="Servicio"
                item-text="nombre"
                item-value="servicio_crm_id"
                v-model="datosLead.servicio_lead_id"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-toolbar>
            <v-btn color="primary" small @click="guardarNuevoLead">
              <v-icon>mdi-content-save</v-icon>Guardar
            </v-btn>
            <v-divider vertical class="px-2" inset></v-divider>
            <v-btn text small @click="dialogCrearLead = false">
              <v-icon>mdi-cancel</v-icon>Cancelar
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
import LeadForm from "./LeadForm.vue";
import router from "../../router";

export default {
  name: "ListaLeads",
  components: { LeadForm },
  data: () => ({
    show: false,

    requiredRule: [(v) => !!v || "El campo es requerido"],

    options: {},
    setfilters: [],
    selected: [],
    users: [],
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 20,
    pageCount: 0,

    required: [(v) => !!v || "El campo es requerido"],
    headersLead: [],
    headers: [
      { text: "Código", align: "start", value: "party_id" },
      { text: "Nombres", align: "start", value: "cliente_nombres" },
      { text: "Estado", align: "start", value: "estado" },
      { text: "Asignado a", align: "start", value: "created_by_user_login" },
    ],
    leads: [],
    dialogoLead: false,
    datosLead: {
      party_id: "",
      status_id: "",
      data_source_id: "",
      acepta_recordatorios: "",
      created_date: "",
      created_by_user_login: "",
      description: "",
      party_type_id: "",
      first_name: "",
      last_name: "",
      telefono: "",
      email: "",
    },
    opLead: [],
    eventosLead: [],
    cotizacionesLead: [],
    fuentes: [],
    dialogCrearLead: false,
    campanias: [],
    servicios: [],
    anuncios: [],
    pipelines: [],
    tiendas: [],
    product_store_id: "",
    pipeline_id: "",
    dialogCotizar: false,
    headersOportLead: [],
    oportunidadesLead: [],
    estados: [],
    busNombre: "",
    busEstado: "",
    busEtapa: "",
    busAsignado: "",
    busCodigo: "",
    busTelefono: "",
    //busFecha: [],
    busFecha: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
    overlay: false,
    
  }),
  computed: {
    ...mapState("master", ["loadingBtn", "companies"]),
    ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),
    ...mapState("master", ["loadingTable", "tenantId", "user"]),
    dateRangeText: {      
        set(val) {
             this.busFecha[0] = val;
             this.busFecha[1] = val;
        },
        get() {
          return this.busFecha.join(" ~ ");
        }
    

        },
    validarTelefono() {
      if (this.datosLead.telefono == null || this.datosLead.telefono == "") {
        return [true];
      }

      if (this.datosLead.telefono.match(/\D/)) {
        return ["Teléfono inválido"];
      }
      let phone = this.datosLead.telefono; // this.datosLead.telefono.replace(/[^0-9]/g, '');
      if (phone != null) {
        if (!(phone.length == 10) && !(phone.length == 9))
          return ["Teléfono inválido"];
      }
    },

    validarEmail() {
      if (this.datosLead.email == "") {
        return [true];
      }
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.datosLead.email
        )
      ) {
        return ["Email inválido"];
      } else {
        return [true];
      }
    },
    getAnuncios() {
      let lista = [];
      for (let i = 0; i < this.anuncios.length; i++) {
        if (
          this.datosLead.marketing_campaign_id ==
          this.anuncios[i].marketing_campaign_id
        ) {
          lista.push(this.anuncios[i]);
        }
      }
      return lista;
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingTable",
      "setTitleToolbar",
      "setTenantId",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapActions("access", []),

    cargarLista() {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("lista-partys");

      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
          itemsPerPage: this.itemsPerPage,
          typeList: "leads",
          paginator: true,
          party_id: this.busCodigo,
          nombres: this.busNombre,
          asignado_a: this.busAsignado,
          status_id: this.busEstado,
          telefono: this.busTelefono,
          desde: this.busFecha[0],
          hasta: this.busFecha[1]
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.leads = res.data._embedded.lista_partys;
          this.pageCount = res.data.page_count;
          this.currentPage = res.data.page == 0 ? 1 : res.data.page;
          this.totalItems = res.data.total_items;
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },
    abrirLeadDialog(leadId) {
      this.dialogoLead = true;
      this.cargarDatosLead(leadId);
    },

    abrirCrearLeadDialog(leadId) {
      this.dialogCrearLead = true;

      this.setUrl("lista-partys/" + leadId);
      this.requestApi({
        method: "GET",
        data: {
          typeFind: "cargarDatosLead",
          party_id: leadId,
        },
      })
        .then((res) => {
          //console.log(res.data)
          this.datosLead = {
            party_id: "",
            status_id: "",
            data_source_id: "",
            acepta_recordatorios: "",
            created_date: "",
            created_by_user_login: "",
            description: "",
            party_type_id: "",
            first_name: "",
            last_name: "",
            telefono: "",
            email: "",
          };
          this.fuentes = res.data.fuentes;
          (this.campanias = res.data.campanias),
            (this.opLead = res.data.oportunidades);
          this.eventosLead = res.data.eventos;
          this.servicios = res.data.servicios;
          this.anuncios = res.data.anuncios;
          this.estados = res.data.estados;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    guardarNuevoLead() {
      if (!this.$refs.formCrearLead.validate()) {
        return false;
      }
      this.setLoadingTable(true);
      this.setUrl("lead");
      this.requestApi({
        method: "POST",
        data: this.datosLead,
      })
        .then((res) => {
          //this.abrirLeadDialog(res.data.detail.party_id);
          //this.dialogCrearLead = false;
          const routeData = this.$router.resolve({
            name: "lead_form",
            query: {
              partyId: this.datosLead.party_id,
            },
          });
          //window.open(routeData.href, "_blank");
          //window.open(routeData.href);
          const pid = res.data.detail.party_id;
          this.$router.push({ path: `/lead_form/${pid}` });

          //window.location.replace("lead_form/" + res.data.detail.party_id);
          //return;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    guardarLead() {
      if (!this.$refs.formLead.validate()) {
        return false;
      }

      this.setLoadingTable(true);
      this.setUrl("lead");
      this.requestApi({
        method: "POST",
        data: this.datosLead,
      })
        .then((res) => {
          //console.log(res.data)
          this.cargarLista();
          this.dialogoLead = false;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    crearOportunidad() {
      if (!this.$refs.formCrearOportunidad.validate()) {
        return false;
      }
      this.setLoadingTable(true);
      this.setUrl("oportunidad");
      this.requestApi({
        method: "POST",
        data: {
          accion: "crearOportunidadLead",
          party_id: this.datosLead.party_id,
          product_store_id: this.product_store_id,
          pipeline_id: this.pipeline_id,
        },
      })
        .then((res) => {
          console.log(res.data);
          if (res.data.detail.result == true) {
            const routeData = this.$router.resolve({
              name: "CrmCotizacion",
              query: {
                accion: "crear_op",
                sop_id: res.data.detail.sop_id,
                lead_id: this.datosLead.party_id,
                quote_id: res.data.detail.quote_id
              },
            });
            window.open(routeData.href, "_blank");
          }
          this.dialogCotizar = false;
          this.dialogoLead = false;
          this.cargarLista();
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    cargarDatosLead(id) {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("lista-partys/" + id);
      this.requestApi({
        method: "GET",
        data: {
          typeFind: "cargarDatosLead",
          party_id: id,
        },
      })
        .then((res) => {
          //console.log(res.data)
          this.datosLead = res.data.party;
          this.fuentes = res.data.fuentes;
          (this.campanias = res.data.campanias),
            (this.opLead = res.data.oportunidades);
          this.eventosLead = res.data.eventos;

          this.servicios = res.data.servicios;
          this.anuncios = res.data.anuncios;
          this.pipelines = res.data.pipelines;
          this.tiendas = res.data.tiendas;
          this.estados = res.data.estados;
          this.overlay = false;
          // console.log("xxxx",this.servicios);
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },
    cargarCampos() {
      this.setLoadingTable(true);

      this.setUrl("lead");

      this.requestApi({
        method: "GET",
        data: {
          view_type: "camposLeads",
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.headersLead = res.data.detail.campos;
          this.estados = res.data.detail.estados;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    limpiarFecha() {      
      this.busFecha=[]
      this.cargarLista()
      
    },
    handlePageChange(value) {
      this.cargarLista();
    },
  },
  mounted() {
    this.cargarCampos();
    this.cargarLista();
    this.setTitleToolbar("LEADS");
  },
};
</script>
